import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type MapView } from '@/types/schema/views/MapView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewDetailsFieldManagement } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsFieldManagement';
import {
  ViewSettingsFiltering,
  type ViewSettingsFilteringOption
} from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';
import { DataDisplayGeneralSettings } from '@/pages/pages/settings-panel/view-settings/list/data-display/DataDisplayGeneralSettings';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function MapDataDisplaySettings() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<MapView>();
  const updateViewSchema = useUpdateView<MapView>();

  const tabListItems: TabsListItem[] = [
    {
      value: 'general',
      children: t('keywords.general')
    },
    {
      value: 'fields',
      children: t('keywords.fields')
    },
    {
      value: 'filtering',
      children: t('keywords.filtering')
    }
  ];

  const mapViewFilteringOptions = {
    filter_type: view.details.filter_type,
    filter_fields: view.details.filter_fields,
    preset_filters: view.details.preset_filters,
    menu_filters: view.details.menu_filters
  };

  const updateMapViewSchema = (options: Partial<ViewSettingsFilteringOption>) =>
    updateViewSchema({ details: { ...view.details, ...options } });

  return (
    <Tabs defaultValue="general" className="h-full">
      <Tabs.List items={tabListItems} intent="page" shouldDisableResponsive shouldUseGradient />
      <Tabs.Content value="general" className="p-0 text-default">
        <DataDisplayGeneralSettings isSortingEnabled={false} />
      </Tabs.Content>
      <Tabs.Content value="fields" className="p-0 text-default">
        <ViewDetailsFieldManagement />
      </Tabs.Content>
      <Tabs.Content value="filtering" className="p-0 text-default">
        <ViewSettingsFiltering
          sourceObject={sourceObject}
          updateViewSchema={updateMapViewSchema}
          options={mapViewFilteringOptions}
        />
      </Tabs.Content>
    </Tabs>
  );
}
