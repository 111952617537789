import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import {
  type TableView,
  type TableViewActionRule,
  type TableViewColumn
} from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { DynamicActionsDialog } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/dynamic-actions/DynamicActionsDialog';
import { ViewDynamicActionCard } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/dynamic-actions/ViewDynamicActionCard';

interface TableViewColumnDynamicActionsProps {
  columnItem: TableViewColumn;
  updateViewColumn: (data: Partial<TableViewColumn>) => void;
}

export function TableViewColumnDynamicActions({
  columnItem,
  updateViewColumn
}: TableViewColumnDynamicActionsProps) {
  const [t] = useTranslation();
  const { sourceObject } = useActiveViewContext<TableView>();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Create a fieldMap to quickly access the fields without having to iterate over sourceObject.fields every time.
  const sourceObjectFieldsMap: Record<KnackFieldKey, KnackField> = useMemo(
    () => Object.fromEntries(sourceObject.fields.map((field) => [field.key, field])),
    [sourceObject]
  );

  const onSaveDynamicAction = (data: TableViewActionRule) => {
    updateViewColumn({
      id: columnItem.id,
      action_rules: columnItem.action_rules ? [...columnItem.action_rules, data] : [data]
    });
  };

  const onUpdateDynamicAction = (data: TableViewActionRule) => {
    const updatedActionRules = columnItem.action_rules?.map((action) =>
      action.key === data.key ? data : action
    );

    updateViewColumn({
      id: columnItem.id,
      action_rules: updatedActionRules
    });
  };

  // const onDuplicateDynamicAction = (data: TableViewActionRule) => {
  //   updateViewColumn({
  //     id: columnItem.id,
  //     action_rules:  ? [...columnItem.action_rules, data] : [data]
  //   });
  // };

  const onDeleteDynamicAction = (index: number) => {
    const updatedActionRules = columnItem.action_rules?.filter((_, i) => i !== index);

    updateViewColumn({
      id: columnItem.id,
      action_rules: updatedActionRules
    });
  };
  return (
    <BuilderAccordion.Item label="Dynamic Actions" isDefaultOpen>
      <div className="rounded-lg border">
        <p className="p-2 text-xs">
          If the criteria for the first dynamic action are met, the subsequent actions will not run
          or be displayed.
        </p>
      </div>
      <Button intent="secondary" className="mt-2" onClick={() => setIsDialogOpen(true)}>
        <PlusIcon size={16} className="mr-1" />
        {t(
          'pages.element_settings.table.categories.data_display.field_management.dynamic_actions_column.dialog_trigger'
        )}
      </Button>
      {columnItem?.action_rules?.length !== 0 &&
        columnItem?.action_rules?.map((action, actionIndex) => (
          <ViewDynamicActionCard
            key={action.key}
            dynamicAction={action}
            dynamicActionNumber={actionIndex + 1}
            sourceObject={sourceObject}
            sourceObjectFieldsMap={sourceObjectFieldsMap}
            onActionSave={onUpdateDynamicAction}
            onActionDuplicate={(data) => console.log('duplicate-action', data)}
            onActionDelete={onDeleteDynamicAction}
          />
        ))}

      {isDialogOpen && (
        <DynamicActionsDialog
          sourceObject={sourceObject}
          onUpdate={onSaveDynamicAction}
          onOpenChange={setIsDialogOpen}
        />
      )}
    </BuilderAccordion.Item>
  );
}
