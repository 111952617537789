import { useTranslation } from 'react-i18next';
import { Input, Label, Switch } from '@knack/asterisk-react';

export function TruncateTextSettings({ columnItem, updateViewColumn }) {
  const [t] = useTranslation();

  return (
    <>
      <Label className="flex items-center gap-2">
        <Switch
          checked={columnItem.truncate}
          onCheckedChange={(val) => updateViewColumn({ id: columnItem.id, truncate: val })}
        />
        {t('pages.element_settings.table.categories.data_display.field_management.truncate_option')}
      </Label>
      {columnItem.truncate && (
        <div className="ml-10 mt-4">
          <Label>
            {t(
              'pages.element_settings.table.categories.data_display.field_management.character_limit'
            )}
            <Input
              className="max-w-20"
              value={columnItem.character_limit}
              onChange={(e) =>
                updateViewColumn({
                  id: columnItem.id,
                  character_limit: Number(e.target.value)
                })
              }
            />
          </Label>
        </div>
      )}
    </>
  );
}
