import { useEffect, useMemo } from 'react';
import { FormProvider, useForm, type FieldErrors } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { type BuilderViewSourceCriteriaRule } from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { CriteriaForm } from '@/components/CriteriaForm';

export interface SourceFilterItemFormSchema {
  criteria: BuilderViewSourceCriteriaRule[];
}

export function SourceFilterDialogGroups({
  sourceFilterGroup,
  sourceFilterGroupIndex,
  sourceFiltersFormErrors,
  sourceObject,
  onChange
}: {
  sourceFilterGroup: BuilderViewSourceCriteriaRule[];
  sourceFilterGroupIndex: number;
  sourceFiltersFormErrors: FieldErrors<{
    criteria: BuilderViewSourceCriteriaRule[][];
  }>;
  sourceObject: KnackObject;
  onChange: (value: { criteria: BuilderViewSourceCriteriaRule[] }) => void;
}) {
  // Extract the error message from the formErrors object and format the new key with only criteria.
  const formattedErrors: FieldErrors<SourceFilterItemFormSchema> | undefined = useMemo(
    () =>
      sourceFiltersFormErrors
        ? Object.keys(sourceFiltersFormErrors).reduce((acc, key) => {
            if (key.includes(`${sourceFilterGroupIndex}:criteria`)) {
              const newKey = key.replace(`${sourceFilterGroupIndex}:`, '');
              acc[newKey] = sourceFiltersFormErrors[key];
            }
            return acc;
          }, {})
        : undefined,
    [sourceFiltersFormErrors, sourceFilterGroupIndex]
  );

  const sourceFiltersItemFormSchema = z.object({
    criteria: z.custom<BuilderViewSourceCriteriaRule[]>()
  });

  const form = useForm<SourceFilterItemFormSchema>({
    resolver: zodResolver(sourceFiltersItemFormSchema),
    defaultValues: {
      criteria: sourceFilterGroup
    },
    errors: formattedErrors
  });

  const sourceFiltersGroupArray = form.watch('criteria');

  useEffect(() => {
    onChange({ criteria: sourceFiltersGroupArray });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sourceFiltersGroupArray)]);

  if (sourceFiltersGroupArray.length === 0) return null;

  return (
    <FormProvider {...form}>
      <CriteriaForm sourceObject={sourceObject} criteriaType="filter" />
    </FormProvider>
  );
}
