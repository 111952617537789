import { useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider, Label, Select } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  RECORD_RULE_ACTION_TYPES,
  RECORD_RULE_VALUE_TYPES,
  type RecordRule,
  type RecordRuleActionType,
  type RecordRuleConnectionKey,
  type RecordRuleValueType
} from '@/types/schema/rules/RecordRule';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useRecordRuleHelpers } from '@/hooks/helpers/useRecordRuleHelpers';
import { cn } from '@/utils/tailwind';
import { CriteriaForm } from '@/components/CriteriaForm';
import {
  getRecordRuleLabels,
  type RecordRuleFormFieldsLabels
} from '@/components/record-rule/helpers';
import { RecordRuleConnectionSelect } from '@/components/record-rule/RecordRuleConnectionSelect';
import { RecordRuleCustomEmailFields } from '@/components/record-rule/RecordRuleCustomEmail';
import { RecordRuleValueGroup } from '@/components/record-rule/RecordRuleValueGroup';

interface RecordRuleFormFieldsProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
  sourcePage?: BuilderPage;
  recordRuleActionTypes?: RecordRuleActionType[];
  recordRuleAllowedValueTypes?: RecordRuleValueType[];
  canCriteriaValuesBeField?: boolean;
  labels?: Partial<RecordRuleFormFieldsLabels>;
  shouldIgnoreCriteria?: boolean;
  valueGroupClassName?: string;
}

export function RecordRuleFormFields({
  recordRule,
  sourceObject,
  sourcePage,
  recordRuleActionTypes = [...RECORD_RULE_ACTION_TYPES], // The consumer can pass the action types they want to display, but by default we use all action types
  recordRuleAllowedValueTypes = [...RECORD_RULE_VALUE_TYPES], // The consumer can pass the allowed value types they want to display, but by default we use all value types
  canCriteriaValuesBeField,
  labels,
  valueGroupClassName,
  shouldIgnoreCriteria = false
}: RecordRuleFormFieldsProps) {
  const [t] = useTranslation();

  const { getConnectionsWithObject } = useObjectHelpers();
  const { getDefaultRecordRuleValue, getRecordRuleValuesObject } = useRecordRuleHelpers();

  const [ruleValuesObject, setRuleValuesObject] = useState<KnackObject>(() =>
    getRecordRuleValuesObject(sourceObject, recordRule.action, recordRule.connection)
  );

  const sourceObjectConnections = getConnectionsWithObject(sourceObject);

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
    watch
  } = useFormContext<RecordRule>();

  const [recordRuleActionType] = watch(['action']);
  const ruleLabels = getRecordRuleLabels(recordRuleActionType, labels);

  const handleChangeRecordRuleActionType = (
    actionType: RecordRuleActionType,
    onFieldChange: ControllerRenderProps['onChange']
  ) => {
    onFieldChange(actionType);

    if (
      (actionType === 'connection' || actionType === 'insert') &&
      sourceObjectConnections.length > 0
    ) {
      const newConnectionKey: RecordRuleConnectionKey = `${sourceObjectConnections[0].object.key}.${sourceObjectConnections[0].connection.key}`;
      const newRuleValuesObject = getRecordRuleValuesObject(
        sourceObject,
        actionType,
        newConnectionKey
      );

      setValue('connection', newConnectionKey);
      setValue('values', [
        getDefaultRecordRuleValue(newConnectionKey, actionType, newRuleValuesObject)
      ]);

      setRuleValuesObject(newRuleValuesObject);
    } else {
      const newRuleValuesObject = getRecordRuleValuesObject(sourceObject, actionType, undefined);

      // Clean connection property if the action type is not connection or insert
      setValue('connection', undefined);
      setValue('values', [getDefaultRecordRuleValue(undefined, actionType, newRuleValuesObject)]);

      setRuleValuesObject(newRuleValuesObject);
    }
  };

  return (
    <>
      <div className="mb-4">
        <p className="mb-2 font-medium">{ruleLabels.actionType}</p>
        <Controller
          control={control}
          name="action"
          render={({ field: { value: selectedActionType, onChange: onFieldChange } }) => (
            <Select
              value={selectedActionType}
              onValueChange={(val: RecordRuleActionType) => {
                handleChangeRecordRuleActionType(val, onFieldChange);
                clearErrors();
              }}
            >
              <Select.Trigger
                data-testid="record-rule-action-select"
                placeholder={t('actions.select')}
                className="w-full"
                intent={errors.action ? 'destructive' : 'default'}
              />
              <Select.Content>
                {recordRuleActionTypes.map((actionType) => {
                  // If there are no connections, don't show the connection or insert options
                  if (
                    sourceObjectConnections.length === 0 &&
                    (actionType === 'connection' || actionType === 'insert')
                  ) {
                    return null;
                  }

                  return (
                    <Select.Item
                      key={actionType}
                      value={actionType}
                      data-testid={`record-rule-action-select-${actionType}`}
                    >
                      {t(`components.record_rule_card.actions.${actionType}`)}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select>
          )}
        />
      </div>
      {(recordRuleActionType === 'connection' || recordRuleActionType === 'insert') && (
        <div className="mb-4">
          <RecordRuleConnectionSelect
            sourceObject={sourceObject}
            onChange={(connectionKey) => {
              const newRuleValuesObject = getRecordRuleValuesObject(
                sourceObject,
                recordRuleActionType,
                connectionKey
              );

              setValue('values', [
                getDefaultRecordRuleValue(connectionKey, recordRuleActionType, newRuleValuesObject)
              ]);

              setRuleValuesObject(newRuleValuesObject);
            }}
            isInsert={recordRuleActionType === 'insert'}
          />
        </div>
      )}
      {recordRuleActionType !== 'email' && (
        <div>
          <Label className="block text-emphasis">{ruleLabels.values}</Label>
          <div className={cn('mt-2 space-y-2 rounded-lg bg-subtle p-2', valueGroupClassName)}>
            <RecordRuleValueGroup
              ruleValuesObject={ruleValuesObject}
              ruleAllowedValueTypes={recordRuleAllowedValueTypes}
              sourceObject={sourceObject}
              sourceObjectConnections={sourceObjectConnections}
              sourcePage={sourcePage}
            />
          </div>
        </div>
      )}
      {recordRuleActionType === 'email' && (
        <RecordRuleCustomEmailFields sourceObject={sourceObject} />
      )}
      {!shouldIgnoreCriteria && (
        <>
          <Divider className="my-6" />
          <h3 className="mb-1 font-medium">{t('components.rules.conditions')}</h3>
          <p className="mb-4 text-xs text-subtle">
            {t('components.rules.apply_action_when_conditions_are_met')}
          </p>
          <div className="rounded-lg bg-subtle">
            <CriteriaForm
              criteriaType="record-rule"
              sourceObject={sourceObject}
              canCriteriaValuesBeField={canCriteriaValuesBeField}
              runEveryTimeText={ruleLabels.runEveryTime}
            />
          </div>
        </>
      )}
    </>
  );
}
