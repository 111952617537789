import flagsmith from 'flagsmith';

// Here we have all the Flagsmith defined with their payload type
// This is only compatible with strings, on the Server repo we have a more complex solution
// https://github.com/knackhq/Server/blob/ad35b377ef99f107733990233e7bdaed43bbb769/app/lib/feature-flags/feature-flags-helper.js#L1
interface FlagsmithKeys {
  albato_flows: boolean;
  pre_made_excels: boolean;
  fe_payments: boolean;
  gt_add_import_v2: boolean;
  full_nextgen_access: boolean;
  only_nextgen_access: boolean;
}

export const FEATURE_FLAGS = {
  albato_flows: 'albato_flows',
  pre_made_excels: 'pre_made_excels',
  fe_payments: 'fe_payments',
  gt_add_import_v2: 'gt_add_import_v2',
  full_nextgen_access: 'full_nextgen_access',
  only_nextgen_access: 'only_nextgen_access'
} as const;

/**
 * Get the value of a flag
 */
export const getFlagValue = <T extends keyof FlagsmithKeys>(key: T) =>
  // We are assuming the Flagsmith key follows the type defined in FlagsmithKeys for this key
  flagsmith.getValue(key) as FlagsmithKeys[T];

/**
 * Return true if the flag is enabled
 */
export const isFlagEnabled = <T extends keyof FlagsmithKeys>(key: T) => flagsmith.hasFeature(key);
