import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { getBuilderBaseUrl } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';
import { isFlagEnabled } from '@/utils/flagsmith';
import {
  type DefaultValue,
  type MatchRule,
  type SerializedMappedColumn,
  type SerializedNewColumn
} from '@/components/import/types';
import { useJobsStore } from '@/components/jobs/useJobsStore';
import { ROUTES } from '@/Router';

async function importFile({
  action = 'create',
  hasHeaderRow,
  match,
  file,
  serializedNewColumns = [],
  serializedMappedColumns = [],
  fileName,
  tableKeyToUpdate,
  selectedSheetIndex,
  shouldSkipRecordsWithErrors,
  isUser = false,
  defaultValues = [],
  shouldRedirectAfterImport = true
}: {
  action?: 'create' | 'update';
  hasHeaderRow: boolean;
  match: { updateRecords: boolean; insertUnmatched: boolean; rules: MatchRule[] };
  file: File;
  serializedNewColumns?: SerializedNewColumn[];
  serializedMappedColumns?: SerializedMappedColumn[];
  fileName?: string;
  tableKeyToUpdate?: string;
  selectedSheetIndex?: number;
  shouldSkipRecordsWithErrors: boolean;
  isUser?: boolean;
  defaultValues?: DefaultValue[];
  shouldRedirectAfterImport: boolean;
}) {
  const form = new FormData();
  form.append('importAction', action);
  form.append('hasHeaderRow', hasHeaderRow.toString());
  form.append('match', JSON.stringify(match));
  form.append('mappedFields', JSON.stringify(serializedMappedColumns));
  form.append('newFields', JSON.stringify(serializedNewColumns));
  form.append('importFile', file);
  form.append('defaultValues', JSON.stringify(defaultValues));
  form.append('isUser', JSON.stringify(isUser));
  form.append('skipRecordsWithErrors', shouldSkipRecordsWithErrors.toString());

  if (fileName) {
    form.append('objectName', fileName);
  }

  if (selectedSheetIndex) {
    form.append('selectedSheetIndex', selectedSheetIndex.toString());
  }

  if (action === 'update' && tableKeyToUpdate) {
    form.append('objectKey', tableKeyToUpdate);
  }

  const endpoint = '/v1/import/start';
  const response = await axiosInstance.post(endpoint, form, { withCredentials: true });

  return { data: response.data, shouldRedirectAfterImport };
}

export function useImportFileMutation(existingTableKey?: string) {
  const actions = useJobsStore((state) => state.actions);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { refetch: refetchApp } = useApplicationQuery();

  return useMutation({
    mutationFn: importFile,
    onSuccess: async (response) => {
      if (!response.shouldRedirectAfterImport) {
        return;
      }

      const shouldRedirectToNewBuilder =
        (searchParams.has('origin') && searchParams.get('origin') === 'builder-next') ||
        isFlagEnabled('full_nextgen_access');

      const tableKey = response.data.changes.inserts.objects[0]?.key || existingTableKey;
      await refetchApp();

      if (shouldRedirectToNewBuilder) {
        actions.jobStarted(response.data.jobId, 'import', tableKey);

        const newBuilderPath = `${generatePath(ROUTES.TABLES_ID, {
          id: tableKey
        })}`;

        navigate(newBuilderPath);
      } else {
        const builderBaseUrl = getBuilderBaseUrl();
        const oldBuilderPath = `${builderBaseUrl}/records/objects/${tableKey}?jobid=${response.data.jobId}`;

        window.location.href = oldBuilderPath;
      }
    }
  });
}
