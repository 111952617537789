import { useCallback, useMemo } from 'react';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';

import {
  type KnackField,
  type KnackFieldKey,
  type KnackFieldType
} from '@/types/schema/KnackField';
import {
  blankOperators,
  characterCountOperators,
  containsOperators,
  dateTimeExtendedRangeOperators,
  dateTimeOperators,
  defaultOperators,
  domainOperators,
  fileTypeOperators,
  geocodingOperators,
  hasChangedOperators,
  hasChangedSpecificOperators,
  isAnyOperators,
  isOneOfOperators,
  isOperators,
  numberOperators,
  regexOperators,
  sizeOperators,
  stringOperators,
  timeOperators,
  type KnackOperator
} from '@/types/schema/KnackOperator';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { type Field } from '@/components/data-table/display/fields/Field';

export const NUMERIC_FIELD_TYPES: KnackFieldType[] = [
  'number',
  'currency',
  'timer',
  'auto_increment',
  'rating',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'equation'
];

export function useFieldHelpers() {
  const { data: application } = useApplicationQuery();
  const objects = useMemo(() => application?.objects ?? [], [application]);

  // Check if the field can store values that can be used as dates
  const canFieldStoreDateValues = (field: KnackField) => {
    if (field.type === 'date_time') {
      return true;
    }

    // Check if the field is equation, and it's formatted to store dates
    if (
      field.type === 'equation' &&
      field.format.equation_type === 'date' &&
      field.format.date_result === 'date'
    ) {
      return true;
    }

    return false;
  };

  const getBaseFieldOperators = (field: KnackField): KnackOperator[] => {
    if (canFieldStoreDateValues(field)) {
      // For date/time fields that just store times, only show time operators
      if (field.type === 'date_time' && field.format.date_format === 'Ignore Date') {
        return timeOperators;
      }

      // Otherwise show date/time operators
      return dateTimeOperators;
    }

    if (field.type === 'address') {
      const appHasSupportForGeocoding =
        application?.settings.hasGeocoding ||
        application?.account.isTrial ||
        (application?.account.productPlan.level && application?.account.productPlan.level > 1);
      const fieldHasGeocodingEnabled = !!field.format.enable_geocoding;

      if (appHasSupportForGeocoding && fieldHasGeocodingEnabled) {
        return [...defaultOperators, ...geocodingOperators];
      }

      return defaultOperators;
    }

    switch (field.type) {
      case 'short_text':
      case 'paragraph_text':
      case 'rich_text':
      case 'concatenation':
      case 'name':
      case 'email':
      case 'phone':
        return defaultOperators;

      case 'number':
      case 'currency':
      case 'equation':
      case 'auto_increment':
      case 'sum':
      case 'min':
      case 'max':
      case 'average':
      case 'count':
      case 'timer':
      case 'rating':
        return numberOperators;

      case 'boolean':
        return [...isOperators, ...blankOperators];

      case 'multiple_choice':
      case 'connection':
      case 'user_roles':
        return [...isOperators, ...containsOperators, ...isAnyOperators, ...blankOperators];

      case 'file':
      case 'image':
      case 'signature':
        return [...blankOperators];

      case 'link':
        return [...blankOperators, ...isOperators];

      case 'password':
        return [...containsOperators, ...stringOperators];

      default:
        return [];
    }
  };

  const getRecordRuleFieldOperators = (field: KnackField): KnackOperator[] => {
    if (field.type === 'signature' || field.type === 'file' || field.type === 'image') {
      return [...getBaseFieldOperators(field), ...hasChangedOperators];
    }

    return [
      ...getBaseFieldOperators(field),
      ...hasChangedOperators,
      ...hasChangedSpecificOperators
    ];
  };

  const getValidationRuleFieldOperators = (field: KnackField): KnackOperator[] => {
    if (canFieldStoreDateValues(field)) {
      // For date/time fields that just store times, only show the base time operators
      if (field.type === 'date_time' && field.format.date_format === 'Ignore Date') {
        return getBaseFieldOperators(field);
      }
      return [...getBaseFieldOperators(field), ...dateTimeExtendedRangeOperators];
    }

    switch (field.type) {
      case 'short_text':
      case 'password':
        return [...getBaseFieldOperators(field), ...characterCountOperators, ...regexOperators];

      case 'paragraph_text':
      case 'rich_text':
        return [...getBaseFieldOperators(field), ...characterCountOperators];

      case 'email':
        return [...getBaseFieldOperators(field), ...domainOperators];

      case 'number':
      case 'currency':
      case 'equation':
      case 'auto_increment':
      case 'sum':
      case 'min':
      case 'max':
      case 'average':
      case 'count':
      case 'timer':
      case 'rating':
        return [...getBaseFieldOperators(field), ...isOneOfOperators];

      case 'file':
      case 'image':
        return [...getBaseFieldOperators(field), ...sizeOperators, ...fileTypeOperators];

      default:
        return getBaseFieldOperators(field);
    }
  };

  function getConnectedFieldInOtherSourceObject(
    connectionFieldKey: `${KnackFieldKey}-${KnackFieldKey}` | string | undefined,
    fieldMap: Record<KnackFieldKey, KnackField>
  ) {
    if (!connectionFieldKey) return null;

    // The connectionFieldKey is made up of `sourceObjectFieldKey`-`targetSourceObjectFieldKey` (e.g. `field_1-field_23` - used in Record rules)
    const connectedFieldKeys = connectionFieldKey.split('-');
    const connectionField = fieldMap[connectedFieldKeys[0]];

    return (
      application?.objects
        .find((object) => connectionField?.relationship?.object === object.key)
        ?.fields.find((field) => field.key === connectedFieldKeys[1]) ?? null
    );
  }

  function convertFieldNamesToFieldKeys(text: string, fields: KnackField[]) {
    let updatedText = text;

    fields.forEach((field) => {
      if (updatedText.includes(field.name)) {
        updatedText = updatedText.replace(field.name, field.key);
      }
    });

    return updatedText;
  }

  function convertFieldKeysToFieldNames(text: string, fields: KnackField[]) {
    let updatedText = text;

    fields.forEach((field) => {
      if (updatedText.includes(field.key)) {
        updatedText = updatedText.replace(field.key, field.name);
      }
    });

    return updatedText;
  }

  // Retrieves the field with the given key
  const getFieldByKey = useCallback(
    (fieldKey: KnackFieldKey) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const object of objects) {
        const field = object.fields.find((f) => f.key === fieldKey);
        if (field) {
          return field;
        }
      }

      return undefined;
    },
    [objects]
  );

  const isFieldValueInvalid = (fieldValue: Partial<Field>) => {
    if (!fieldValue.rawValue || !fieldValue.type) {
      return true;
    }

    switch (fieldValue.type) {
      case 'phone': {
        if (includes(fieldValue.rawValue, '_')) {
          return true;
        }
        break;
      }
      case 'date_time':
      case 'link':
      case 'email':
      case 'signature': {
        if (includes(fieldValue.rawValue, '')) {
          return true;
        }
        break;
      }
      case 'timer': {
        const timerValue = fieldValue.rawValue.times[0];
        if (includes(timerValue.from, '') || includes(timerValue.to, '')) {
          return true;
        }
        break;
      }
      case 'boolean': {
        if (isNil(fieldValue.rawValue)) {
          return true;
        }
        break;
      }
      default: {
        return false;
      }
    }
    return false;
  };

  return {
    getBaseFieldOperators,
    getRecordRuleFieldOperators,
    getValidationRuleFieldOperators,
    getConnectedFieldInOtherSourceObject,
    convertFieldNamesToFieldKeys,
    convertFieldKeysToFieldNames,
    getFieldByKey,
    isFieldValueInvalid,
    canFieldStoreDateValues
  };
}
