import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Badge, Button, Collapsible, Tooltip } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type TableViewActionRule } from '@/types/schema/views/TableView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackValue } from '@/components/InlineKnackValue';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';
import { ViewRecordRuleCardConnectionContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardConnectionContent';
import { ViewRecordRuleCardEmailContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardEmailContent';
import { ViewRecordRuleRecordCard } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardRecordContent';
import { SortableCardWrapper } from '@/pages/pages/settings-panel/view-settings/common/SortableCardWrapper';
import { DynamicActionsDialog } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/dynamic-actions/DynamicActionsDialog';

const MAX_NUMBER_OF_CONDITIONS = 4;

interface ViewDynamicActionCardProps {
  dynamicAction: TableViewActionRule;
  dynamicActionNumber: number;
  sourceObject: KnackObject;
  sourceObjectFieldsMap: Record<KnackFieldKey, KnackField>;
  isInvalid?: boolean;
  shouldScrollIntoView?: boolean;
  isDragOverlay?: boolean;
  runEveryTimeText?: string;
  onActionSave: (data: TableViewActionRule) => void;
  onActionDuplicate: (displayRuleToDuplicate: TableViewActionRule) => void;
  onActionDelete: (index: number) => void;
}

export function ViewDynamicActionCard({
  dynamicAction,
  dynamicActionNumber,
  sourceObject,
  sourceObjectFieldsMap,
  isInvalid = false,
  shouldScrollIntoView = false,
  isDragOverlay = false,
  runEveryTimeText,
  onActionSave,
  onActionDuplicate,
  onActionDelete
}: ViewDynamicActionCardProps) {
  const [t] = useTranslation();

  const dynamicActionCardRef = useRef<HTMLDivElement | null>(null);
  const [isEditActionModalOpen, setIsEditActionModalOpen] = useState(false);
  const { getSelectablePageLabelFromSlug, getExistingChildPageLabelFromSlug } = usePageHelpers();

  const submitRule = dynamicAction.submit_rules[0];

  useEffect(() => {
    if (shouldScrollIntoView && dynamicActionCardRef.current) {
      dynamicActionCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dynamicAction?.key) {
    return null;
  }

  return (
    <div className="mt-2">
      <SortableCardWrapper
        ref={dynamicActionCardRef}
        sortableItemId={dynamicAction.key}
        isDragOverlay={isDragOverlay}
        isInactiveState={isInvalid}
        onEditClick={() => setIsEditActionModalOpen(true)}
        onDuplicateClick={() => onActionDuplicate(dynamicAction)}
        onDeleteClick={() => onActionDelete(dynamicActionNumber)}
      >
        <div className="mb-2 inline-flex items-center">
          <span className="text-xs font-medium text-emphasis">
            {t('components.display_rule_card.rule_number', {
              number: dynamicActionNumber
            })}
          </span>

          {isInvalid && (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Badge intent="warning" className="ml-2 cursor-pointer leading-none">
                  {t('components.display_rule_card.invalid_rule_badge')}
                </Badge>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="max-w-80" side="bottom">
                  {t('components.display_rule_card.invalid_form_rule_tooltip')}
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip>
          )}
        </div>
        {/* // TODO: Continue working on this component - FE-4013 */}
        <p className="my-2 text-xs">BUTTON TEXT</p>
        <InlineKnackValue value={dynamicAction.link_text} className="bg-action" />
        <p className="my-2 text-xs">CRITERIA</p>
        {!dynamicAction.criteria.length && (
          <span className="text-xs">
            {runEveryTimeText || t('components.rules.run_every_time')}
          </span>
        )}
        {dynamicAction.criteria.map((criteria, index) => {
          const criteriaField = sourceObjectFieldsMap[criteria.field];
          const isLastCriteria = index === dynamicAction.criteria.length - 1;
          const isMaxNumberOfConditionsReached = index === MAX_NUMBER_OF_CONDITIONS - 1;

          if (index >= MAX_NUMBER_OF_CONDITIONS) {
            return null;
          }

          if (isMaxNumberOfConditionsReached) {
            return (
              <Button
                key="max-conditions"
                intent="link"
                className="focus:bg-transparent active:bg-transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditActionModalOpen(true);
                }}
              >
                + {dynamicAction.criteria.length + 1 - MAX_NUMBER_OF_CONDITIONS}{' '}
                {t('keywords.more')}
              </Button>
            );
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${criteria.field}-${index}`}>
              {criteriaField ? (
                <div className="flex flex-wrap items-center gap-1">
                  <span>{t('components.rules.when')}</span>
                  <InlineKnackField
                    fieldType={criteriaField.type}
                    fieldName={criteriaField.name}
                    className="border-none bg-action"
                  />{' '}
                  {t(`operators.${snakeCase(criteria.operator)}`)}{' '}
                  <InlineKnackCriteriaValue
                    criteria={criteria}
                    criteriaField={criteriaField}
                    className="bg-action"
                  />
                </div>
              ) : (
                <EmptyRuleValue />
              )}

              {!isLastCriteria && <div className="my-1">{t('components.rules.and_uppercase')}</div>}
            </Fragment>
          );
        })}
        {/* //? RECORD RULES */}
        {dynamicAction.record_rules.map((recordRule, recordRuleIndex) => {
          if (!recordRule.action) {
            return <EmptyRuleValue key={recordRule.key} />;
          }
          return (
            <Collapsible
              key={recordRule.key}
              defaultOpen={recordRuleIndex < 3}
              className="my-2 w-full"
            >
              <Collapsible.Trigger className="mt-2 flex w-full items-center justify-between [&_svg]:transition-transform [&_svg]:data-[state=open]:rotate-180">
                <div className="flex items-center gap-4">
                  <p className="text-xs">ACTION #{recordRuleIndex + 1}</p>
                  <span className="text-xs">{recordRule.values.length} values</span>
                </div>
                <ChevronIcon size={12} />
              </Collapsible.Trigger>
              <Collapsible.Content className="w-full">
                <div className="mb-2 mt-1 flex flex-wrap items-center gap-1">
                  {(recordRule.action === 'insert' || recordRule.action === 'connection') && (
                    <ViewRecordRuleCardConnectionContent
                      sourceObject={sourceObject}
                      recordRule={recordRule}
                    />
                  )}
                  {recordRule.action === 'email' && (
                    <ViewRecordRuleCardEmailContent
                      recordRule={recordRule}
                      sourceObject={sourceObject}
                      setIsEditRuleModalOpen={setIsEditActionModalOpen}
                    />
                  )}
                  {recordRule.action === 'record' && (
                    <ViewRecordRuleRecordCard
                      recordRule={recordRule}
                      sourceObjectFieldsMap={sourceObjectFieldsMap}
                    />
                  )}
                </div>
              </Collapsible.Content>
            </Collapsible>
          );
        })}
        {/* OUTCOME */}
        <p className="my-2 text-xs">OUTCOME</p>
        {submitRule.action === 'message' && (
          <InlineKnackValue
            value={t('components.submit_rule_card.actions.message')}
            className="border-none bg-action"
          />
        )}

        {submitRule.action === 'existing_page' && submitRule.existing_page && (
          <div className="flex flex-col items-start gap-1">
            <InlineKnackValue
              value={t('components.submit_rule_card.actions.existing_page')}
              className="border-none bg-action"
            />
            <InlineKnackValue
              value={getSelectablePageLabelFromSlug(sourceObject.key, submitRule.existing_page)}
              className={submitRule.existing_page ? 'underline' : undefined}
            />
          </div>
        )}

        {submitRule.action === 'child_page' && (
          <div className="flex flex-col items-start gap-1">
            <InlineKnackValue
              value={t('components.submit_rule_card.actions.child_page')}
              className="border-none bg-action"
            />
            {typeof submitRule.scene === 'string' ? (
              <InlineKnackValue
                value={getExistingChildPageLabelFromSlug(submitRule.scene)}
                className={submitRule.scene ? 'underline' : undefined}
              />
            ) : (
              <InlineKnackValue
                value={submitRule.scene?.name ?? '-'}
                className={submitRule.scene ? 'underline' : undefined}
              />
            )}
          </div>
        )}

        {submitRule.action === 'url' && (
          <div className="flex flex-col items-start gap-1">
            <InlineKnackValue
              value={t('components.submit_rule_card.actions.url')}
              className="border-none bg-action"
            />
            <InlineKnackValue
              value={submitRule.url ?? '-'}
              className={submitRule.url ? 'underline' : undefined}
            />
          </div>
        )}
      </SortableCardWrapper>

      {isEditActionModalOpen && (
        <DynamicActionsDialog
          onOpenChange={setIsEditActionModalOpen}
          sourceObject={sourceObject}
          onUpdate={onActionSave}
          action={dynamicAction}
        />
      )}
    </div>
  );
}
