/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useRef } from 'react';
import { type Virtualizer } from '@tanstack/react-virtual';
import { type AxiosError } from 'axios';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackRecord } from '@/types/schema/KnackRecord';
import { type RecordAsset } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { axiosInstance } from '@/utils/axiosConfig';
import { createCustomSelectors, createSelectors } from '@/utils/zustand';
import {
  type DateTimeRawValue,
  type FileFieldRawValueFormat
} from '@/components/data-table/display/fields/Field';
import { apiHelper } from '@/components/data-table/helpers/apiHelper';
import { getDefaultValueFromField } from '@/components/data-table/helpers/getDefaultValueFromField';
import { type DataTableRow, type DataTableRowValue } from './display/types';
import {
  DEFAULT_ROW_HEIGHT,
  PAGINATION_MODE_MAX_RECORDS,
  ROWS_PER_PAGE
} from './helpers/constants';
import { convertKnackRecordToDataTableRow, getRecords } from './helpers/getRecords';
import { getRowHeight } from './helpers/getRowHeight';
import { createDataTableStorePersist } from './useDataTableStorePersist';

type ServerErrorType = {
  message: string;
  field: string;
};

export type ServerErrorResponseType = {
  errors: ServerErrorType[];
};

const initialState = {
  rowVirtualizer: null,
  currentFieldSettings: null,
  isInitialLoad: true,
  isFetchingPages: false,
  pagesInViewport: [],
  objectKey: '',
  tableMetadata: null,
  rowHeight: DEFAULT_ROW_HEIGHT,
  fields: [],
  data: {},
  dataOrder: [],
  totalRecords: 0,
  totalPages: 0,
  selectedCell: null,
  filesBeingUploaded: {},
  rowErrorList: {},
  persist: null as unknown as ReturnType<typeof createDataTableStorePersist>,

  draftRows: {},
  originalRowsData: {},
  currentLoadingRows: {},

  dataNavigationMode: 'infinite-scroll'
} satisfies Partial<DataTableStore>;

type DataTableStore = {
  draftRows: Record<string, any>;
  originalRowsData: Record<string, any>;
  currentLoadingRows: Record<string, boolean>;

  rowVirtualizer: Virtualizer<HTMLDivElement, Element> | null;
  currentFieldSettings: string | null;
  isInitialLoad: boolean;
  isFetchingPages: boolean;

  pagesInViewport: number[];
  objectKey: string;
  tableMetadata: KnackObject | null;
  rowHeight: number;
  fields: KnackField[];
  data: Record<string, DataTableRow['fields']>;
  dataOrder: string[]; // Array of rowIDs in order
  totalRecords: number;
  totalPages: number;
  selectedCell: {
    rowId: string;
    fieldId: string;
    isEditing: boolean;
  } | null;
  filesBeingUploaded: { [key: string]: boolean };

  rowErrorList: { [key: string]: { [key: string]: { message: string }[] | undefined } };

  dataNavigationMode: 'pagination' | 'infinite-scroll';
  persist: ReturnType<typeof createDataTableStorePersist>;
  actions: {
    // Actions
    initialize: ({
      app,
      objectKey
    }: {
      app: BuilderApplication;
      objectKey: string;
    }) => Promise<void>;
    loadPage: (page: number) => Promise<void>;
    setRow: (row: DataTableRow) => void;
    deleteRow: (rowId: string) => void;

    setSelectedCell: (rowId: string, fieldId: string) => void;
    deselectCell: () => void;
    setIsEditing: (isEditing: boolean) => void;
    setPagesInViewport: (pages: number[]) => void;
    refetchPages: (pages: number[]) => void;
    refetchPagesInViewport: () => void;
    reset: () => void;
    setCurrentFieldSettings: (fieldKey: string | null) => void;
    setRowVirtualizer: (rowVirtualizer: Virtualizer<HTMLDivElement, Element> | null) => void;

    setCellErrors: (rowId: string, fieldId: string, errors: { message: string }[]) => void;
    clearCellErrors: (rowId: string, fieldId: string) => void;
    setFileIsBeingUploaded: (rowId: string, fieldId: string, loading: boolean) => void;

    getRowsPerPage: () => number;

    saveCell: (
      rowId: string,
      fieldId: string,
      value: unknown,
      valueRender: DataTableRowValue,
      assets?: RecordAsset[]
    ) => Promise<void>;

    saveDraftRow: (rowId: string, withoutValidations?: boolean) => Promise<void>;
    cancelRowEdit: (rowId: string) => Promise<void>;
    cancelFieldEdit: (rowId: string, fieldId: string) => void;
    newRow: () => string;
  };
};

export const createDataTableStore = () => {
  // Create a store that will be used to persist the state of the data table in the url.
  const dataTableStorePersist = createDataTableStorePersist();

  const dataTableStore = create<DataTableStore>()(
    immer((set, get) => ({
      ...initialState,
      persist: dataTableStorePersist,
      actions: {
        // Actions
        initialize: async ({ app, objectKey }) => {
          const { actions } = get();
          actions.reset();

          set((draft) => {
            draft.isInitialLoad = true;
          });

          const { page } = dataTableStorePersist.getState();

          const tableMetadata = app?.objects.find((obj) => obj.key === objectKey);
          // If the objectKey is incorrect we throw an error, since we don't have access in the store to react-hooks we will handle this error wherever this is called.
          if (!tableMetadata) throw new Error(`Object ${objectKey} not found in application`);

          const pageToLoad = page !== null ? page - 1 : 0;

          set((draft) => {
            draft.tableMetadata = tableMetadata;
            draft.objectKey = objectKey;
            draft.rowHeight = getRowHeight(tableMetadata.fields);
            draft.fields = tableMetadata.fields;
            draft.pagesInViewport = [pageToLoad];
          });

          if (!dataTableStorePersist.getState().sortBy) {
            // load from local storage or select the first column
            const savedState = localStorage.getItem(`table-url-params-${objectKey}`);
            if (savedState) {
              dataTableStorePersist.setState(JSON.parse(savedState));
            } else {
              // Without a defined sortBy we will sort by the first field in the table.
              dataTableStorePersist.setState(() => ({
                sortBy: tableMetadata.fields[0].key,
                sortOrder: 'asc',
                shouldSortAutomatically: true
              }));
            }
          }

          await actions.loadPage(pageToLoad);

          set((draft) => {
            draft.isInitialLoad = false;
          });
        },
        setCurrentFieldSettings: (fieldKey) => {
          set((draft) => {
            draft.currentFieldSettings = fieldKey;
          });
        },
        setRowVirtualizer: (rowVirtualizer) => {
          // Instead of using immer we use the setState set to avoid creating a draft of the virtualizer
          // which causes type problems with immer and its draft.
          dataTableStore.setState({
            ...dataTableStore.getState(),
            rowVirtualizer
          });
        },
        loadPage: async (page) => {
          const { pagesInViewport, objectKey, actions, totalRecords } = get();
          const {
            sortBy,
            sortOrder,
            filters,
            search,
            page: urlPage,
            rowsPerPage,
            actions: persistActions
          } = dataTableStorePersist.getState();

          set((draft) => {
            draft.isFetchingPages = true;
            draft.dataNavigationMode = urlPage || rowsPerPage ? 'pagination' : 'infinite-scroll';

            if (draft.dataNavigationMode === 'pagination' && draft.dataOrder.length > 0) {
              draft.dataOrder = new Array(actions.getRowsPerPage()).fill(null);
            }
          });

          // This can only happen if the store is not initialised properly. It will not break the app.
          if (!objectKey) throw new Error('You should initiliaze the store before loading a page');

          const pageResult = await getRecords({
            objectKey,
            page: page + 1,
            recordsPerPage: actions.getRowsPerPage(),
            sortField: sortBy || undefined,
            sortOrder: sortOrder || undefined,
            filters: filters || undefined,
            search: search || undefined
          });

          if (pageResult.total_records !== totalRecords) {
            // If a record has been created/deleted we refetch other pages. This is not ideal but it's the only way to keep the data consistent until Websockets.
            actions.refetchPages(pagesInViewport.filter((inViewPage) => inViewPage !== page));
          }

          set((draft) => {
            if (pageResult.total_records === 0) {
              draft.dataOrder = [];
            }
            draft.dataNavigationMode =
              pageResult.total_records >= PAGINATION_MODE_MAX_RECORDS
                ? 'pagination'
                : 'infinite-scroll';

            pageResult.records.forEach((element) => {
              draft.data[element.id] = element.fields;
            });

            // If we are in infinite loading mode, we fill the dataOrder array with empty rows that will fill the scroll and we will fill later with data
            if (draft.dataNavigationMode === 'infinite-scroll') {
              if (draft.dataOrder.length === 0 && pageResult.total_records >= 0) {
                draft.dataOrder = new Array(pageResult.total_records).fill(null);
              }

              draft.dataOrder.splice(
                page * actions.getRowsPerPage(),
                pageResult.records.length,
                ...pageResult.records.map((record) => record.id)
              );

              if (urlPage || rowsPerPage) {
                persistActions.setPage(null);
                persistActions.setRowsPerPage(null);
              }
            } else {
              draft.dataOrder = pageResult.records.map((record) => record.id);
            }

            draft.totalRecords = pageResult.total_records;
            draft.totalPages = pageResult.total_pages;
            draft.isFetchingPages = false;
          });
        },
        getRowsPerPage: () => {
          const { dataNavigationMode } = get();
          const { rowsPerPage } = dataTableStorePersist.getState();

          if (dataNavigationMode === 'infinite-scroll') {
            return ROWS_PER_PAGE;
          }

          return rowsPerPage || ROWS_PER_PAGE;
        },
        setRow: async (row) => {
          set((draft) => {
            draft.data[row.id] = row.fields;
          });
        },
        deleteRow: async (rowId) => {
          set((draft) => {
            if (!draft.data[rowId]) return;

            delete draft.data[rowId];
            draft.dataOrder = draft.dataOrder.filter((id) => id !== rowId);
            draft.totalRecords -= 1;
          });
        },
        setSelectedCell: (rowId, fieldId) => {
          set((draft) => {
            draft.selectedCell = {
              rowId,
              fieldId,
              isEditing: false
            };
          });
        },
        deselectCell: () => {
          set((draft) => {
            draft.selectedCell = null;
          });
        },
        setIsEditing: (isEditing) => {
          const { selectedCell } = get();
          if (selectedCell) {
            set((draft) => {
              if (!draft.selectedCell) return;
              draft.selectedCell.isEditing = isEditing;
            });
          }
        },
        setCellErrors: (rowId, fieldId, errors) => {
          set((draft) => {
            if (!draft.rowErrorList[rowId]) {
              draft.rowErrorList[rowId] = {};
            }
            draft.rowErrorList[rowId][fieldId] = errors;
          });
        },
        clearCellErrors: (rowId, fieldId) => {
          set((draft) => {
            if (draft.rowErrorList[rowId] && draft.rowErrorList[rowId][fieldId]) {
              delete draft.rowErrorList[rowId][fieldId];
              if (Object.keys(draft.rowErrorList[rowId]).length === 0) {
                delete draft.rowErrorList[rowId];
              }
            }
          });
        },
        setFileIsBeingUploaded: (rowId, fieldId, loading) => {
          set((draft) => {
            if (!loading) {
              delete draft.filesBeingUploaded[`${rowId}_${fieldId}`];
              return;
            }

            draft.filesBeingUploaded[`${rowId}_${fieldId}`] = loading;
          });
        },
        newRow: () => {
          const newRowId = `new_row_${nanoid()}`;
          set((draft) => {
            // Create a virtual row that has no values
            draft.data[newRowId] = draft.fields.reduce(
              (acc, field) => ({
                ...acc,
                [field.key]: getDefaultValueFromField(field, draft.tableMetadata)
              }),
              {}
            );
            draft.draftRows[newRowId] = {};
            draft.dataOrder.push(newRowId);
          });
          return newRowId;
        },
        cancelRowEdit: async (rowId) => {
          const { actions } = get();
          actions.deselectCell();
          // We wait for deselection of the cell, this way we ensure that the cell is cleaned up with the new data and doesn't contain the one introduced in the inputs
          setTimeout(() => {
            set((draft) => {
              if (draft.rowErrorList[rowId]) {
                delete draft.rowErrorList[rowId];
              }

              delete draft.draftRows[rowId];

              if (rowId.startsWith('new_row')) {
                // If it's a new row delete the virtual row from the data and ordered Ids
                delete draft.data[rowId];
                draft.dataOrder.splice(draft.dataOrder.indexOf(rowId), 1);
              } else {
                // if its an existing row rollback to the original data
                draft.data[rowId] = draft.originalRowsData[rowId];
              }
              delete draft.originalRowsData[rowId];
            });
          }, 1);
        },
        cancelFieldEdit: (rowId, fieldId) => {
          set((draft) => {
            if (draft.draftRows[rowId]) {
              if (draft.draftRows[rowId][fieldId]) {
                delete draft.draftRows[rowId][fieldId];
              }

              if (Object.keys(draft.draftRows[rowId]).length === 0) {
                delete draft.draftRows[rowId];
              }

              draft.data[rowId][fieldId] = draft.originalRowsData[rowId][fieldId];
            }
          });
        },

        saveDraftRow: async (rowId, withoutValidations = false) => {
          const { objectKey, actions, draftRows, data, fields } = get();
          const { sortBy, shouldSortAutomatically } = dataTableStorePersist.getState();

          if (!draftRows[rowId]) {
            return;
          }

          const taskId = useGlobalState.getState().actions.createPendingTask();

          set((draft) => {
            draft.currentLoadingRows[rowId] = true;
            if (draft.rowErrorList[rowId]) {
              delete draft.rowErrorList[rowId];
            }
          });

          try {
            const rowValues = {
              // When we save, we send all the fields, even if they are not edited.
              // Some fields need to send the raw value to the server instead of value and others need some conversion from what is received and what the server expects.
              ...Object.entries(data[rowId]).reduce((acc, [key, rowvalue]) => {
                const field = fields.find((f) => f.key === key);
                // Some fields need to send the raw value to the server instead of value.
                if (
                  field?.type === 'timer' ||
                  field?.type === 'address' ||
                  field?.type === 'name' ||
                  field?.type === 'email' ||
                  field?.type === 'multiple_choice' ||
                  field?.type === 'link' ||
                  field?.type === 'signature' ||
                  field?.type === 'connection' ||
                  field?.type === 'paragraph_text' ||
                  field?.type === 'number' ||
                  field?.type === 'user_roles'
                ) {
                  return { ...acc, [key]: rowvalue.rawValue };
                }
                if (field?.type === 'file' || field?.type === 'image') {
                  return { ...acc, [key]: (rowvalue.rawValue as FileFieldRawValueFormat).id || '' };
                }

                // IMPORTANT: The server sends us the date as MM/DD/YYYY, but it expects us to send it back the proper format. So we add the formatted date to the request.
                if (field?.type === 'date_time') {
                  const values = rowvalue.rawValue as DateTimeRawValue;
                  return {
                    ...acc,
                    [key]: {
                      ...values,
                      date: values.date_formatted
                    }
                  };
                }

                // We send other fields non edited fields to check the validations on them.
                // For password field we don't need that.
                if (field?.type === 'password') {
                  return acc;
                }

                return { ...acc, [key]: rowvalue.value };
              }, {}),
              ...draftRows[rowId]
            };

            let response: KnackRecord;
            const queryString = withoutValidations ? '?disableValidation=true' : '';
            if (rowId.startsWith('new_row')) {
              // Check if there are required fields that are not filled
              const requiredFields = fields.filter(
                (field) => field.required && !rowValues[field.key]
              );

              if (!withoutValidations && requiredFields.length > 0) {
                requiredFields.forEach((field) => {
                  actions.setCellErrors(rowId, field.key, [
                    {
                      message: t('components.data_table.errors.required_field')
                    }
                  ]);
                });

                useGlobalState.getState().actions.stopPendingTask(taskId);
                set((draft) => {
                  delete draft.currentLoadingRows[rowId];
                });

                return;
              }

              response = (
                await apiHelper(axiosInstance).newRow({
                  objectKey,
                  queryString,
                  rowValues
                })
              ).data;
            } else {
              response = (
                await apiHelper(axiosInstance).saveRow({
                  objectKey,
                  rowId,
                  queryString,
                  rowValues
                })
              ).data;
            }

            set((draft) => {
              if (draft.draftRows) {
                delete draft.draftRows[rowId];
              }
              delete draft.originalRowsData[rowId];
            });

            if (
              shouldSortAutomatically &&
              sortBy &&
              Object.keys(draftRows[rowId]).includes(sortBy) &&
              Object.keys(draftRows).length === 1
            ) {
              actions.refetchPagesInViewport();
            }

            useGlobalState.getState().actions.stopPendingTask(taskId);
            set((draft) => {
              delete draft.currentLoadingRows[rowId];
            });

            actions.setRow(convertKnackRecordToDataTableRow(response));

            if (rowId.startsWith('new_row')) {
              set((draft) => {
                delete draft.data[rowId];
                draft.dataOrder.splice(draft.dataOrder.indexOf(rowId), 1, response.id);
                draft.totalRecords += 1;
              });
            }

            actions.setIsEditing(false);
          } catch (errors: unknown) {
            const errorResponse = errors as AxiosError<ServerErrorResponseType>;

            set((draft) => {
              if (!draft.rowErrorList[rowId]) {
                draft.rowErrorList[rowId] = {};
              }
              delete draft.currentLoadingRows[rowId];
            });
            useGlobalState.getState().actions.stopPendingTask(taskId);

            errorResponse.response?.data.errors.forEach((error) => {
              if (!errorResponse.response) return;
              actions.setCellErrors(
                rowId,
                error.field,
                errorResponse.response.data.errors.filter(
                  (errorFilter) => errorFilter.field === error.field
                )
              );
            });
          }
        },
        saveCell: async (rowId, fieldId, value, valueRender) => {
          set((draft) => {
            if (!draft.draftRows[rowId]) {
              draft.draftRows[rowId] = {};
            }

            draft.draftRows[rowId][fieldId] = value;

            // We save the current row data
            if (!draft.originalRowsData[rowId]) {
              draft.originalRowsData[rowId] = { ...draft.data[rowId] };
            }

            if (draft.data[rowId] && draft.data[rowId][fieldId]) {
              draft.data[rowId][fieldId] = valueRender;
            }

            if (draft.rowErrorList[rowId] && draft.rowErrorList[rowId][fieldId]) {
              delete draft.rowErrorList[rowId][fieldId];
              if (Object.keys(draft.rowErrorList[rowId]).length === 0) {
                delete draft.rowErrorList[rowId];
              }
            }
          });
        },
        setPagesInViewport: (pages) => {
          const { pagesInViewport } = get();
          if (pagesInViewport !== pages) {
            set((draft) => {
              draft.pagesInViewport = pages;
            });
          }
        },
        refetchPages: async (pagesToRefetch: number[]) => {
          const { actions } = get();
          set((draft) => {
            draft.data = {};
            draft.dataOrder = [];
          });
          pagesToRefetch.forEach((page) => {
            void actions.loadPage(page);
          });

          actions.deselectCell();
        },
        refetchPagesInViewport: async () => {
          const { actions, pagesInViewport } = get();
          actions.refetchPages(pagesInViewport);
        },
        reset: () => {
          dataTableStorePersist.getState().actions.reset();
          set((draft) => {
            Object.keys(draft).forEach((key) => {
              if (!initialState[key]) return;

              draft[key] = initialState[key];
            });
          });
        }
      }
    }))
  );

  const computedSelectors = {
    getValue: (rowId: string, fieldKey: string) =>
      dataTableStore(
        useShallow((state) => (state.data[rowId] && state.data[rowId][fieldKey]) || null)
      ),

    getField: <T extends KnackFieldType>(fieldKey: string) =>
      dataTableStore(
        useShallow(
          (state) =>
            state.fields.find((field) => field.key === fieldKey) as Extract<KnackField, { type: T }>
        )
      ),

    getCellValues: (rowId: string) =>
      dataTableStore(useShallow((state) => state.data[rowId] ?? null)),

    getSelectedCellValues: () =>
      dataTableStore(
        useShallow(
          (state) =>
            (state.selectedCell &&
              state.data[state.selectedCell?.rowId] &&
              state.data[state.selectedCell?.rowId][state.selectedCell?.fieldId]) ||
            null
        )
      ),

    getSelectedField: () =>
      dataTableStore(
        useShallow((state) =>
          state.fields.find((field) => field.key === state.selectedCell?.fieldId)
        )
      ),

    isCellSelected: (rowId: string, fieldKey: string) =>
      dataTableStore(
        useShallow(
          (state) =>
            state.selectedCell &&
            state.selectedCell.rowId === rowId &&
            state.selectedCell.fieldId === fieldKey
        )
      ),

    rowErrors: (rowId: string) =>
      dataTableStore(useShallow((state) => state.rowErrorList[rowId] || null)),
    cellErrors: (rowId: string, fieldKey: string) =>
      dataTableStore(
        useShallow(
          (state) => (state.rowErrorList[rowId] && state.rowErrorList[rowId][fieldKey]) || null
        )
      ),
    cellHasFilesPending: (rowId: string, fieldKey: string) =>
      dataTableStore(
        useShallow((state) => state.filesBeingUploaded[`${rowId}_${fieldKey}`] || false)
      ),

    rowHasFilesPending: (rowId: string) =>
      dataTableStore(
        useShallow(
          (state) =>
            Object.keys(state.filesBeingUploaded).some((key) => key.includes(`${rowId}_`)) || false
        )
      ),

    draftRowsKeys: () => dataTableStore(useShallow((state) => Object.keys(state.draftRows))),
    isFieldModified: (rowId: string, fieldKey: string) =>
      dataTableStore(
        useShallow(
          (state) => state.draftRows[rowId] && state.draftRows[rowId][fieldKey] !== undefined
        )
      ),
    isDraftModeEnabled: () =>
      dataTableStore(useShallow((state) => Object.keys(state.draftRows).length > 0))
  };

  // Adds custom selectors and create the automatic selectors that will live inside useDataTableStore().use
  return createCustomSelectors(createSelectors(dataTableStore), computedSelectors);
};

const StoreContext = createContext<ReturnType<typeof createDataTableStore>>(
  null as unknown as ReturnType<typeof createDataTableStore>
);

export function DataTableStoreProvider({ children }) {
  const storeRef = useRef<ReturnType<typeof createDataTableStore>>();
  if (!storeRef.current) {
    storeRef.current = createDataTableStore();
  }
  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>;
}

export const useDataTableStorePersist = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('Missing StoreProvider');
  }
  return store.getState().persist;
};

export const useDataTableStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('Missing DataTable StoreProvider');
  }
  return store;
};
