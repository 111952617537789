import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { z } from 'zod';

import { type KnackCriteriaWithValueType } from '@/types/schema/KnackCriteria';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormView, type FormViewSubmitRule } from '@/types/schema/views/FormView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { isDateTimeRangeOperator, shouldHideValueBasedOnOperator } from '@/utils/field-operators';

export function useSubmitRuleHelpers() {
  const [t] = useTranslation();

  const { getDefaultCriteriaOperator, getDefaultCriteriaValue, isCriteriaValueEmpty } =
    useCriteriaHelpers();

  const getSubmitRuleFormSchema = (sourceObject: KnackObject) =>
    z.custom<FormViewSubmitRule>().superRefine((data, ctx) => {
      if (data.action === 'message' && !data.message) {
        ctx.addIssue({
          path: ['message'],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      if (data.action === 'url' && !data.url) {
        ctx.addIssue({
          path: ['url'],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      // If the action is 'child_page' and there isn't a child page already associated with the rule, we need to validate the name of the new child page.
      // If `scene` exists but it's a string, it means it is the slug of an existing child page. If it's an object, it means it's the data for a new child page to be created.
      if (
        data.action === 'child_page' &&
        data.scene &&
        typeof data.scene !== 'string' &&
        !data.scene.name.trim()
      ) {
        ctx.addIssue({
          path: ['scene'],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      if (data.action === 'existing_page' && !data.existing_page) {
        ctx.addIssue({
          path: ['existing_page'],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      // Validate criteria values
      data.criteria?.forEach((criteria, criteriaIndex) => {
        // Validate criteria value if the value type is not a field and the operator requires a value
        const isValueRequired =
          criteria.value_type !== 'field' &&
          !shouldHideValueBasedOnOperator(criteria.operator) &&
          !isDateTimeRangeOperator(criteria.operator);

        const selectedCriteriaField = sourceObject.fields.find(
          (field) => field.key === criteria.field
        );
        const isValueEmpty = selectedCriteriaField
          ? isCriteriaValueEmpty(criteria.value, selectedCriteriaField)
          : true;

        if (isValueRequired && isValueEmpty) {
          ctx.addIssue({
            path: [`criteria.${criteriaIndex}.value`],
            message: t('errors.value_required'),
            code: 'custom'
          });
        }

        // Validate criteria value field if the value type is a field and the operator requires a value
        const isFieldRequired =
          criteria.value_type === 'field' && !shouldHideValueBasedOnOperator(criteria.operator);
        const isFieldMissing = !criteria.value_field;

        if (isFieldRequired && isFieldMissing) {
          ctx.addIssue({
            path: [`criteria.${criteriaIndex}.value_field`],
            message: t('errors.value_required'),
            code: 'custom'
          });
        }
      });
    });

  const getDefaultSubmitRuleCriteria = (
    sourceObject: KnackObject,
    formAction: FormView['action']
  ) => {
    if (!sourceObject.fields) {
      return undefined;
    }

    if (sourceObject.fields.length === 0) {
      return undefined;
    }

    const firstFieldInObject = sourceObject.fields[0];

    const defaultSubmitRuleCriteria: KnackCriteriaWithValueType = {
      field: firstFieldInObject.key,
      operator: getDefaultCriteriaOperator(firstFieldInObject, 'submit-rule'),
      value: getDefaultCriteriaValue(firstFieldInObject),
      ...(formAction === 'update' && { value_type: 'custom' })
    };

    return defaultSubmitRuleCriteria;
  };

  const getDefaultSubmitRule = (ruleMessage?: string) => {
    const submitRule: FormViewSubmitRule = {
      key: `submit_${nanoid(10)}`,
      criteria: [],
      action: 'message',
      is_default: false,
      reload_show: false,
      message: ruleMessage ?? t('views.new_view_defaults.form.submit_rule_message')
    };

    return submitRule;
  };

  return {
    getSubmitRuleFormSchema,
    getDefaultSubmitRuleCriteria,
    getDefaultSubmitRule
  };
}
