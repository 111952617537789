import { Dialog } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type TableViewActionRule } from '@/types/schema/views/TableView';
import { DynamicActionsDialogContent } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/dynamic-actions/DynamicActionsDialogContent';

interface DynamicActionsDialogProps {
  action?: TableViewActionRule;
  sourceObject: KnackObject;
  onUpdate: (data: TableViewActionRule) => void;
  onOpenChange: (isOpen: boolean) => void;
}

export function DynamicActionsDialog({
  sourceObject,
  action,
  onUpdate,
  onOpenChange
}: DynamicActionsDialogProps) {
  const handleColumnActionSubmit = (data: TableViewActionRule) => {
    onUpdate(data);
    onOpenChange(false);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <DynamicActionsDialogContent
          sourceObject={sourceObject}
          action={action}
          onFormSubmit={handleColumnActionSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
}
